<template>
  <div>
    <b-modal ref="active-imei-modal" hide-footer title="Kích hoạt IMEI">
      <b-row>
        <b-col>
          <b-form-group class="required-control">
            <label>IMEI:</label>
            <b-form-input
              v-model="mainModel.imeiCode"
              size="sm"
              placeholder="Nhập mã IMEI"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="required-control">
            <label>SĐT Khách hàng:</label>
            <b-form-input
              v-model="mainModel.customerPhone"
              size="sm"
              placeholder="Nhập SĐT khách hàng"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group class="required-control">
            <label>Ngày kích hoạt:</label>
            <date-picker
              placeholder="Từ ngày"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="mainModel.sellDate"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="required-control">
            <label>Cửa hàng:</label>
            <vue-autosuggest
              aria-describedby="input-sitecode-live-feedback"
              :suggestions="filteredOptionsStore"
              @selected="onSelectedStore"
              :limit="10"
              @input="onInputChangeStore"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Cửa hàng',
              }"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading
              "
              v-model="mainModel.storeName"
              ><div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div>
                  <span>{{ suggestion.item.name }}</span>
                </div>
              </div>
            </vue-autosuggest>
            <b-form-invalid-feedback
              :state="isValidSiteCode"
              id="input-sitecode-live-feedback"
              >{{ messageErrorSitecode }}</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!isValidSiteCode">
        <b-col>
          <p class="text-danger">
            <span>&#42;</span> Nếu không chọn cửa hàng hoặc cửa hàng chưa được
            đăng ký siteCode, hệ thống sẽ tự động chọn kênh ONLINE gửi yêu cầu
            kích hoạt.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <span
            v-if="mainModel.message"
            class="label font-weight-bold label-lg label-inline"
            v-bind:class="{
              'label-light-warning': mainModel.status === 1,
              'label-light-success': mainModel.status === 2,
              'label-light-danger': mainModel.status === 3,
              'label-light-default': mainModel.status === 4,
            }"
            v-text="mainModel.message"
            style="height: auto"
          />
        </b-col>
      </b-row>
      <b-button
        style="fontweight: 600; width: 100px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Kích hoạt</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { xoa_dau, makeToastFaile } from '../../../utils/common';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import moment from 'moment';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mainModel: {
        customerPhone: '',
        imeiCode: '',
        showroomCode: '',
        storeName: '',
        sellDate: moment(),
        status: 1,
        message: '',
      },
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      isValidSiteCode: true,
      messageErrorSitecode: '',
    };
  },
  mounted() {},
  components: {
    datePicker,
  },
  methods: {
    showModal: function (
      imeiCode,
      customerPhone = '',
      storeName = '',
      siteCode = ''
    ) {
      this.isValidSiteCode = this.checkValidSiteCode(storeName, siteCode);
      this.fetchStore();
      this.mainModel = {
        customerPhone: customerPhone,
        imeiCode: imeiCode,
        showroomCode: siteCode,
        storeName: storeName,
        sellDate: moment(),
        status: 1,
        message: '',
      };
      this.$refs['active-imei-modal'].show();
    },
    hideModal: function () {
      this.$refs['active-imei-modal'].hide();
    },
    onSelectedStore(option) {
      this.mainModel.showroomCode = option.item.siteCode;
      this.mainModel.storeName = option.item.name;
      this.isValidSiteCode = this.checkValidSiteCode(
        this.mainModel.storeName,
        this.mainModel.showroomCode
      );
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }

      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.siteCode.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptionsStore = [
        {
          data: filteredData,
        },
      ];
    },
    onSubmit() {
      if (!this.mainModel.storeName || !this.mainModel.storeName.trim()) {
        this.mainModel.showroomCode = '';
      }
      const data = {
        imeiCode: this.mainModel.imeiCode
          ? this.mainModel.imeiCode.trim()
          : null,
        showroomCode: this.mainModel.showroomCode
          ? this.mainModel.showroomCode
          : null,
        sellDate: this.mainModel.sellDate
          ? moment(this.mainModel.sellDate, 'DD-MM-YYYY').format('YYYYMMDD')
          : null,
        customerPhone: this.mainModel.customerPhone
          ? this.mainModel.customerPhone.trim()
          : null,
        indexNo: `DDV` + moment(),
      };
      if (!data.imeiCode) {
        return makeToastFaile('Vui lòng nhập mã IMEI cần kích hoạt!');
      }
      if (!data.customerPhone) {
        return makeToastFaile('Vui lòng nhập SĐT khách hàng!');
      }
      if (!data.sellDate) {
        return makeToastFaile('Vui lòng chọn ngày kích hoạt!');
      }
      if (!data.showroomCode) {
        data.showroomCode = 'C058971562';
      }
      ApiService.post('imeis/active-imei', [data])
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            const results = response.data.data;
            if (results && results.length) {
              this.mainModel.status = results[0].status;
              this.mainModel.message = results[0].message;
            }
          } else {
            makeToastFaile(message);
          }
        })
        .catch((e) => {
          console.log('Catch: ', e);
          makeToastFaile(e);
        });
    },
    fetchStore: async function () {
      this.optionsStore[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push({
            ...element,
            siteCode: element.siteCode ? element.siteCode : '',
          });
        });
        this.filteredOptionsStore = [{ data: this.optionsStore[0].data }];
      });
    },
    checkValidSiteCode(storeName, siteCode) {
      if (!storeName) {
        this.messageErrorSitecode = 'Chưa chọn cửa hàng kích hoạt bảo hành!';
        return false;
      }
      if (!siteCode) {
        this.messageErrorSitecode = 'Cửa hàng chưa được đăng ký siteCode!';
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

</style>
