<template>
  <div>
    <b-modal ref="change-gift-modal" hide-footer title="Đổi quà tặng">
      <v-form ref="form" lazy-validation>
        <div v-for="(item, i) in listItem" :key="i" class="mb-4">
          <input type="radio" v-model="productId" :value="item.productId" />
          {{ item.productName }} | Giá bán:
          {{ convertPrice(item.sellingPrice) }}
        </div>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="onSubmit"
          >Áp dụng</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import { convertPrice } from '../../../utils/common';

export default {
  props: ['productId', 'listProductVariant'],
  data() {
    return {
      listItem: [],
    };
  },
  methods: {
    convertPrice,
    showModal: function () {
      this.listItem = this.listProductVariant;
      this.$refs['change-gift-modal'].show();
    },
    hideModal: function () {
      this.$refs['change-gift-modal'].hide();
    },
    onSubmit() {
      this.$emit('changeGift', this.productId);
      this.hideModal();
    },
  },
};
</script>