<template>
  <div>
    <b-modal
      ref="add-flexible-combo-modal"
      hide-footer
      :title="modalTitle"
      size="xl"
    >
      <b-table
        :items="listItem"
        :fields="fields"
        bordered
        hover
        v-if="renderComponent"
      >
        <template v-slot:cell(name)="row">
          <div @click="onChangeProduct(row.item)">
            <vue-autosuggest
              v-model="row.item.name"
              :suggestions="filteredOptions"
              @selected="onSelected"
              :limit="10"
              @input="onInputChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Nhập sản phẩm',
                style: 'border-radius:0px!important',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div>
                  <span class="text-primary"
                    >({{ convertPrice(suggestion.item.listedPrice) }}) (</span
                  >
                  <span
                    v-if="suggestion.item.quantityInStock > 0"
                    class="text-success"
                    >Tồn CTB: {{ suggestion.item.quantityInStock }}</span
                  >
                  <span v-else class="text-danger"
                    >Tồn CTB: {{ suggestion.item.quantityInStock }}</span
                  >
                  )
                  {{ suggestion.item.name }}
                </div>
              </div>
            </vue-autosuggest>
          </div>
        </template>
        <template slot="bottom-row">
          <td
            v-bind:colspan="2"
            class="text-right font-weight-bolder"
            style="font-weight: 600,color: '#181c32'"
          >
            <span>Tổng</span>
          </td>
          <td class="text-right font-weight-bolder">
            <span>{{ convertPrice(sumQuantity) }}</span>
          </td>
          <td class="text-right font-weight-bolder">
            <span>{{ convertPrice(sumProductPrice) }}</span>
          </td>
          <td class="text-right font-weight-bolder">
            <span>{{ convertPrice(sumDiscountAmount) }}</span>
          </td>
          <td class="text-right font-weight-bolder">
            <span>{{ convertPrice(sumTotalAmount) }}</span>
          </td>
          <td></td>
        </template>
      </b-table>
      <b-button
        style="fontweight: 600; width: 100px"
        variant="primary"
        size="sm"
        @click="onSave"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}

.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
#autosuggest {
  width: 100%;
}
</style>

<script>
import { makeToastFaile, convertPrice } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { TIME_OUT, TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';
import { sumBy } from 'lodash';
import { AMOUNT_TYPE, PRODUCT_TYPE, BILL_ITEM_TYPE } from '@/utils/enum';

export default {
  props: ['flexibleComboItem', 'storeId'],
  data() {
    return {
      listItem: [],
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'productPrice',
          label: 'Giá niêm yết',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
      ],
      filteredOptions: [
        {
          data: [],
        },
      ],
      optionsProduct: [],
      renderComponent: true,
      currentIndex: 0,
      sumQuantity: 0,
      sumDiscountAmount: 0,
      sumTotalAmount: 0,
      sumProductPrice: 0,
      productLoading: false,
      PRODUCT_TYPE: PRODUCT_TYPE,
      BILL_ITEM_TYPE: BILL_ITEM_TYPE,
    };
  },
  computed: {
    modalTitle() {
      return `Thêm sản phẩm cho combo: ${this.flexibleComboItem.name}`;
    },
    numProChoose: {
      cache: false,
      get() {
        return this.listItem.filter((item) => item.productId).length;
      },
    },
  },
  mounted() {},
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    showModal: function () {
      this.listItem = [];
      this.getInfoById();
      this.$refs['add-flexible-combo-modal'].show();
    },
    hideModal() {
      this.$refs['add-flexible-combo-modal'].hide();
    },
    convertPrice,
    onSave() {
      const childroductIds = this.listItem.map((item) => item.productId);
      if (childroductIds.includes(null)) {
        return makeToastFaile('Vui lòng chọn sản phẩm cho bộ combo!');
      }
      const listProductInCombo = this.listItem.map((item) => {
        return {
          productName: item.name,
          productNameVat: '',
          productCode: '',
          imeiCode: '',
          productId: item.productId,
          sellingPrice: item.productPrice,
          quantity: item.quantity,
          discount: 0,
          quantityInStock: item.quantityInStock,
          flexibleComboItemId: item.id,
          discountAmount: item.discountAmount,
          totalAmount: item.totalAmount,
        };
      });
      const result = {
        name: this.flexibleComboItem.name,
        productNameVat: '',
        code: this.flexibleComboItem.code,
        imeiCode: '',
        productId: this.flexibleComboItem.id,
        price: this.sumTotalAmount,
        quantity: 1,
        discount: 0,
        discountType: AMOUNT_TYPE.MONEY,
        note: '',
        discountProgramId: null,
        type: PRODUCT_TYPE.PRODUCT_COMBO,
        billItemType: BILL_ITEM_TYPE.PRODUCT,
        listedPrice: 0,
        checked: true,
        isGetVat: false,
        comboItems: listProductInCombo,
        flexibleComboId: this.flexibleComboItem.id,
        billItemId: this.flexibleComboItem.billItemId,
      };
      if (!this.flexibleComboItem.billItemId) {
        this.$emit('addComboChild', result);
      } else {
        this.$emit('editComboChild', result);
      }
      this.hideModal();
    },
    getInfoById() {
      this.listItem = this.flexibleComboItem.listItem.map((item, index) => {
        return {
          ...item,
          count: index + 1,
          discountAmount: 0,
          totalAmount: 0,
          name: '',
        };
      });

      const items = this.flexibleComboItem.listItem;
      this.listItem.forEach((element) => {
        const item = items.find((x) => x.flexibleComboItemId === element.id);
        element.productId = item ? item.productId : null;
        element.name = item ? item.productName : '';
        element.discountAmount = item ? item.discountAmount : 0;
        element.productPrice = item ? item.listedPrice : 0;
        element.totalAmount = item ? item.totalAmount : 0;
      });
    },
    onChangeProduct(selectedItem) {
      this.currentIndex = this.listItem.findIndex(
        (item) => item.id == selectedItem.id
      );
      this.optionsProduct = [];
      this.filteredOptions = [
        {
          data: [],
        },
      ];
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      if (!text) {
        this.listItem[this.currentIndex].productId = null;
      }
      this.debounceInput(text);
    },
    debounceInput: decounce(function (textSearch) {
      this.fetchProduct(textSearch);
    }, TIME_TRIGGER),
    onSelected(option) {
      if (
        option.item.quantityInStock <
          this.listItem[this.currentIndex].quantity &&
        option.item.productType === PRODUCT_TYPE.PRODUCT
      ) {
        this.listItem[this.currentIndex].name = '';
        makeToastFaile('Số lượng tồn kho sản phẩm không đủ!');
        return;
      }
      let discountAmount =
        (option.item.discountRate * option.item.listedPrice) / 100;
      if (option.item.discountType === AMOUNT_TYPE.MONEY) {
        discountAmount = option.item.discountRate;
      }
      this.listItem[this.currentIndex].productId = option.item.productId;
      this.listItem[this.currentIndex].name = option.item.name;
      this.listItem[this.currentIndex].productPrice = option.item.listedPrice;
      this.listItem[this.currentIndex].discountAmount =
        this.roundMoney(discountAmount);
      const totalAmount =
        option.item.listedPrice * this.listItem[this.currentIndex].quantity -
        this.roundMoney(discountAmount);
      this.listItem[this.currentIndex].totalAmount = totalAmount;
      this.listItem[this.currentIndex].quantityInStock =
        option.item.quantityInStock;
      this.calComboValue();
      console.log('Item: ', this.listItem[this.currentIndex]);
    },
    fetchProduct(textSearch) {
      if (!textSearch) {
        return;
      }
      const item = this.listItem[this.currentIndex];
      const params = {
        textSearch: textSearch,
        storeId: this.storeId,
        referenceType: item.type,
        referenceId: item.referenceId,
      };
      this.optionsProduct = [];
      this.filteredOptions = [
        {
          data: this.optionsProduct,
        },
      ];
      this.productLoading = true;
      ApiService.query(
        '/flexible-combo/search-product',
        { params },
        { timeout: TIME_OUT }
      ).then((response) => {
        const dataset = response.data.data;
        this.optionsProduct = dataset.map((element) => {
          return {
            productId: element.productId,
            name: element.productName,
            productCode: element.productCode,
            productNameVat: '',
            sellingPrice: element.sellingPrice,
            returnSellingPrice: 0,
            productType: element.productType,
            imeiCode: '',
            listAccessoryBonus: [],
            listAccessoryPromotion: [],
            quantityInStock: element.quantityInStock,
            listedPrice: element.listedPrice,
            discountRate: item.discountRate,
            discountType: item.discountType,
          };
        });
        this.filteredOptions = [
          {
            data: this.optionsProduct,
          },
        ];
        this.productLoading = false;
      });
    },
    calComboValue() {
      this.sumQuantity = sumBy(this.listItem, 'quantity');
      this.sumProductPrice = sumBy(this.listItem, 'productPrice');
      this.sumDiscountAmount = sumBy(this.listItem, 'discountAmount');
      this.sumTotalAmount = sumBy(this.listItem, 'totalAmount');
    },
    roundMoney(value) {
      const tmpValue = Math.floor(value / 1000);
      return tmpValue * 1000;
    },
  },
};
</script>
