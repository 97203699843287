<template>
  <div class="container">
    <b-modal
      ref="bill-attach-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="xl"
    >
      <v-form ref="form">
        <b-row class="mb-4">
          <b-col md="4">
            <b-input
              size="sm"
              placeholder="Tìm kiếm"
              v-model="searchText"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          show-empty
          class="product-table"
        >
          <template #empty="scope">
            <h5 class="text-center">
              Không có sản phẩm bán kèm nào được gợi ý
            </h5>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="isCheckAll"
                size="lg"
                @change="clickAll"
              ></b-form-checkbox>
            </span>
          </template>
          <!-- check bõ -->
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.checked"
                @change="clickIndex()"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(id)="row">
            <div>
              <span
                v-text="row.item.id"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(discountValue)="row">
            <div>
              {{ convertPrice(row.item.discountValue) }}
              ({{ DISCOUNT_AMOUNT_TYPE_NAME[row.item.discountType] }})
            </div>
          </template>
        </b-table>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="submit"
        >Áp dụng</b-button>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >
          Hủy
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<style scoped>
.product-table /deep/ th {
  text-align: center;
  font-weight: 600;
  color: '#181c32';
}
</style>

<script>
import { convertPrice, xoa_dau } from '@/utils/common';
import { BILL_ITEM_TYPE } from '@/utils/enum';
import { DISCOUNT_AMOUNT_TYPE_NAME } from '@/utils/enum-name';
import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';

export default {
  props: ['billItemId', 'productName', 'storeId', 'attachItems'],
  data() {
    return {
      DISCOUNT_AMOUNT_TYPE_NAME,
      modalTitle: '',
      searchText: '',
      listItem: [],
      listItemDraff: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          tdClass: 'text-right',
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'discountValue',
          label: 'Chiết khấu',
          sortable: false,
          tdClass: 'text-right',
        },
        {
          key: 'returnPrice',
          label: 'Giá chiết khấu',
          sortable: false,
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        { key: 'code', label: 'Mã BPK' },
      ],
      isCheckAll: false,
    };
  },
  methods: {
    convertPrice,
    showModal: function () {
      this.searchText = '';
      this.listItem = this.attachItems.map((element) => {
        const code = `SP bán kèm- ${element.accessoryGroupCode}`;
        return {
          id: v4(),
          productId: element.productId,
          name: element.productName,
          productNameVat: element.productNameVat,
          code: code,
          price: element.sellingPrice,
          quantity: 1,
          type: element.productType,
          imeiCode: '',
          totalPrice: element.sellingPrice,
          discount: 0,
          quantityInStock: element.quantityInStock,
          storeId: this.storeId,
          billItemType: BILL_ITEM_TYPE.PRODUCT_PROMTION,
          checked: false,
          belongBillDetailId: this.billItemId,
          discountType: element.discountType,
          discountValue: element.discountValue,
          returnPrice: element.price,
          accessoryGroupId: element.accessoryGroupId,
          accessoryGroupCode: element.accessoryGroupCode,
          listProductVariant: element.listProductVariant,
          isGetVat: false,
        };
      });
      this.listItemDraff = cloneDeep(this.listItem);
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Thêm sản phẩm bán kèm cho sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      this.$refs['bill-attach-modal'].show();
    },
    hideModal: function () {
      this.$refs['bill-attach-modal'].hide();
    },
    clickAll() {
      this.listItem.forEach((element) => {
        element.checked = this.isCheckAll;
      });
    },
    clickIndex() {
      let check = true;
      for (let index = 0; index < this.listItem.length; index++) {
        const element = this.listItem[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.isCheckAll = check;
    },
    onFilter() {
      const textSearch = this.searchText ? this.searchText.trim() : '';
      this.listItem = this.listItemDraff
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return (
            nameNoneSign.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
          );
        })
        .slice(0, 10);
    },
    submit() {
      const listResult = this.listItem.filter((item) => item.checked);
      this.$emit('addAttach', listResult);
      this.hideModal();
    },
  },
};
</script>